import React from 'react';
import { Routes, Route } from 'react-router-dom';


import Home from './Home';

import A404 from './404';
import About from './About';
import Account from './Account';
import Login from './Login';
import Register from './Register';
import Analytics from './Analytics';
//import { Link } from "react-router-dom";

const Main = () => {

  return (
    <Routes> {/* The Switch decides which component to show based on the current URL.*/}
      <Route path='/' element={<Home/>} />      
      <Route path='/about-us/*' element={<About/>} />   
      <Route path='/account' element={<Account/>} />   
      <Route path='/account/*' element={<Analytics/>} />   
      <Route path='/login/*' element={<Login/>} />   
      <Route path='/register/*' element={<Register/>} />   
      <Route path='/Admin/*' element={<Account/>}/>
      <Route path='*' element={<A404/>} />
    </Routes>
  );
}

export default Main;