import $ from 'jquery';


//API PATH
//const  apiurl  = "../API/";
//export const  apiurl  = "http://127.0.0.1/linkclick/API/";
//export const  apiurl  = "https://digitalaboki.net/API_LINKCLICK/API/";
export const  apiurl  = "https://osegbuecharles.com/API_LINKCLICK/API/";

//APIS

//API call for login 
export function redirectApi(link,ip,device,brand,name,os,country){
  var settings = {
      "url": apiurl+"getLink/index.php",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": {
        "criteria":"link",
        "value": link,
        "ip":ip,
        "device":device,
        "brand":brand,
        "name":name,
        "os":os,
        "country":country
      }
    };
        return new Promise(resolve => {
      $.ajax(settings).done(function (response,status) {
          //console.log(status);
          resolve(response);
        });
  });
}


//API call for getting meta
export function getMeta(url){
  var settings = {
      "url": "https://osegbuecharles.com/getMeta/index.php",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": {
        "url": url,        
      }
    };
        return new Promise(resolve => {
      $.ajax(settings).done(function (response,status) {
          //console.log(status);
          resolve(response);
        });
  });
}

//API call for login 
export function loginApi(email,password){
    var settings = {
        "url": apiurl+"login/index.php",
        "method": "POST",
        "timeout": 0,
        "headers": {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        "data": {
          "email": email,
          "password": password
        }
      };
          return new Promise(resolve => {
        $.ajax(settings).done(function (response,status) {
            //console.log(status);
            resolve(response);
          });
    });
}
 
//API call for register into admin portal
export function registerApi(email,password){
  var settings = {
      "url": apiurl+"register/index.php",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": {
        "email": email,
        "password": password,         
      }
    };
        return new Promise(resolve => {
      $.ajax(settings).done(function (response,status) {
          //console.log(status);
          resolve(response);
        });
  });
}
  
//API call for changing password
export function changePasswordApi(email,oldpassword,newpassword){
  var settings = {
      "url": apiurl+"changePassword/index.php",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": {
        "email": email,
        "oldpassword": oldpassword,
        "newpassword":newpassword
      }
    };
        return new Promise(resolve => {
      $.ajax(settings).done(function (response,status) {
          //console.log(status);
          resolve(response);
        });
  });
}


//API call for newLink 
export function newLinkApi(email,password,link){
  var settings = {
      "url": apiurl+"newLink/index.php",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": {
        "email": email,
        "password": password,
        "link":link
      }
    };
        return new Promise(resolve => {
      $.ajax(settings).done(function (response,status) {
          //console.log(status);
          resolve(response);
        });
  });
}


//API call for getting all link
export function getLinkByIdApi(id){
  var settings = {
      "url": apiurl+"getLink/index.php",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": {    
        "criteria":"id",
        "value":id,                
      }
    };
        return new Promise(resolve => {
      $.ajax(settings).done(function (response,status) {
          //console.log(status);
          resolve(response);
        });
  });
}

//API call for getting all link
export function getLinkByLinkApi(link){
  var settings = {
      "url": apiurl+"getLink/index.php",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": {    
        "criteria":"check",
        "value":link,                
      }
    };
        return new Promise(resolve => {
      $.ajax(settings).done(function (response,status) {
          //console.log(status);
          resolve(response);
        });
  });
}

//API call for getting all link
export function getLinkApi(email,pageNum,pageSize){
  var settings = {
      "url": apiurl+"getLink/index.php",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": {    
        "criteria":"all",        
        "email":email,
        "pageNum":pageNum,
        "pageSize":pageSize
        
      }
    };
        return new Promise(resolve => {
      $.ajax(settings).done(function (response,status) {
          //console.log(status);
          resolve(response);
        });
  });
}

//API call for deleting link by id
export function deleteLinkApi(email,password,id){
  var settings = {
      "url": apiurl+"deleteLink/index.php",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": {    
        "email":email,
        "password":password,
        "id":id
      }
    };
        return new Promise(resolve => {
      $.ajax(settings).done(function (response,status) {
          //console.log(status);
          resolve(response);
        });
  });
}


//API call for customizing link
export function customizeLinkApi(email,password,id,link){
  var settings = {
      "url": apiurl+"customizeLink/index.php",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": {    
        "email":email,
        "password":password,
        "id":id,
        "link":link
      }
    };
        return new Promise(resolve => {
      $.ajax(settings).done(function (response,status) {
          //console.log(status);
          resolve(response);
        });
  });
}


//API call for updating link
export function updateLinkApi(email,password,id,link){
  var settings = {
      "url": apiurl+"updateLink/index.php",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": {    
        "email":email,
        "password":password,
        "id":id,
        "link":link
      }
    };
        return new Promise(resolve => {
      $.ajax(settings).done(function (response,status) {
          //console.log(status);
          resolve(response);
        });
  });
}

//API call for getting analytics
export function getAnalyticsApi(email,password){
  var settings = {
      "url": apiurl+"getAnalytics/index.php",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": {    
        "email":email,
        "password":password,       
      }
    };
        return new Promise(resolve => {
      $.ajax(settings).done(function (response,status) {
          //console.log(status);
          resolve(response);
        });
  });
}



//API call for getting link analytics
export function getLinkAnalyticsApi(email,password,id,pageNum,pageSize){
  var settings = {
      "url": apiurl+"getLinkAnalytics/index.php",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": {    
        "email":email,
        "password":password,       
        "id":id,
        "pageNum":pageNum,
        "pageSize":pageSize
      }
    };
        return new Promise(resolve => {
      $.ajax(settings).done(function (response,status) {
          //console.log(status);
          resolve(response);
        });
  });
}

//API call for getting link analytics
export function getSettingsApi(name){
  var settings = {
      "url": apiurl+"getSettings/index.php",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": {    
        "name":name,        
      }
    };
        return new Promise(resolve => {
      $.ajax(settings).done(function (response,status) {
          //console.log(status);
          resolve(response);
        });
  });
}






