//import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import React, {useState, useEffect} from 'react';
import App from './components/App';
import "./assets/fontawesome/css/font-awesome.min.css";
import reportWebVitals from './reportWebVitals';
import "./assets/boxicons/css/boxicons.min.css";
//import { connect, Provider } from 'react-redux';
//import { createStore, combineReducers } from 'redux';
//import './Counter';
import $ from 'jquery';
import * as api from './api';


import 'bootstrap/dist/css/bootstrap.min.css';

/*
// You can choose your kind of history here (e.g. browserHistory)
import { Router, hashHistory as history } from 'react-router';
// Your routes.js file
import routes from './routes';
*/

import { BrowserRouter } from 'react-router-dom';

const currentURL = window.location.href // returns the absolute URL of a page

const pathname = window.location.pathname //returns the current url minus the domain name

var p=pathname.split("/")
//console.log(currentURL);
//console.log(p[1]);

$(document).ready(async function(){
  var d= await api.loginApi("osegbuecharles@gmail.com","ebmUv6CO")
  //console.log(d);
});



ReactDOM.render((
    <BrowserRouter>
      <App /> {/* The various pages will be displayed by the `Main` component. */}
    </BrowserRouter>
    ), 
    document.getElementById('root')
);


//setInterval(show,1000);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
