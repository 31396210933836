
import '../App.css';
import * as React from 'react';

import {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


import '../css/fonts.css';
import '../css/home.css';
import $ from 'jquery';

//import { render } from '@testing-library/react';
//import {Button,ButtonGroup,ButtonToolbar,Modal,ModalBody,ModalCOntext,ModalDialog,ModalFooter,ModalHeader,ModalTitle} from 'react-bootstrap';
import {Button,Modal} from 'react-bootstrap';
import { Toast, ToastContainer} from 'react-bootstrap';
import * as api from '../api';
import Footer from './Footer';

function Home(props) { 

  const [newLink,setNewLink]=useState('');
  var handleLink=(e)=>{    
    setNewLink(e.target.value);
  }

  //THIS HANDLES THE MODAL
  const [show, setShow] = useState(false);  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const[modalHead,setModalHead]=useState("Link Click");
  const changeModalHead=(val)=>setModalHead(val);

  const[modalBody,setModalBody]=useState("Link Click");
  const changeModalBody=(val)=>setModalBody(val);
  

  //THIS HANDLES THE TOAST
  const [notification, setNotification] = useState(false);
  const handleNotification = () => setNotification(true);
  
  const[notificationHead,setNotificationHead]=useState("");
  const changeNotificationHead=(val)=>setNotificationHead(val);

  const[notificationSmallHead,setNotificationSmallHead]=useState("");
  const changeNotificationSmallHead=(val)=>setNotificationSmallHead(val);

  const[notificationMessage,setNotificationMessage]=useState("");
  const changeNotificationMessage=(val)=>setNotificationMessage(val);



  //SETTIN FORM EMAIL AND PASSWORD
//const [email,setEmail]=useState('');
 // var changeEmail=(e)=>{setEmail(e.target.value)};
  /*
  useEffect(()=>{    
    console.log(email)
  },[email]);
  */
  //const [password,setPassword]=useState('');
  //var changePassword=(e)=>{setPassword(e.target.value)};
  

  //
  const getStarted= async(e)=>{
    e.preventDefault();    
    
    console.log(newLink);
    var heading="Login/Register";
    var body=(<div>
      <form method="POST"  onSubmit={Start} autoComplete='false'>        
          <input type="email" id="email" className="form-control" placeholder="Email"  minLength={3} required/>
          <br/>
          <input type="password" id="password" className="form-control" placeholder="Password" minLength={6}  required/>
          <br/>
          <button className="btn btn-primary" type="submit">Submit</button>
      </form>
    </div>);
    changeModalHead(heading);
    changeModalBody(body);
    handleShow();            
  }

  const Start=async(e)=>{
    e.preventDefault()
    var email=$("#email").val();
    var password=$("#password").val();
        
    var check=await api.registerApi(email,password);
    if(check.error==true){
      var dat=await api.newLinkApi(email,password,newLink);
      if(dat.error=="FALSE"){
        changeNotificationHead("New Short Link");
        changeNotificationSmallHead("success");
        changeNotificationMessage(<div>
          Short link created. See here <a href={dat.data.share}>{dat.data.share}</a>
        </div>);
       
      }
      else{
        changeNotificationHead("New Short Link");
        changeNotificationSmallHead("error");
        changeNotificationMessage(<div>
            {dat.message}
        </div>);
      }
    }
    else{
      dat=await api.newLinkApi(email,password,newLink);
      if(dat.error=="false"){
        changeNotificationHead("New Short Link");
        changeNotificationSmallHead("success");
        changeNotificationMessage(<div>
           An account was created for you!
           <br/>
          Short link created. See here <a href={dat.data.share}>{dat.data.share}</a>
          <br/>         
        </div>);        
      }     
    }
    handleClose();
    handleNotification();
  }
  
  return (
    <>
   <div className="home-page">  
       <ToastContainer className="p-3" position='top-end'>
          <Toast  className="" onClose={() => setNotification(false)} show={notification} >
            <Toast.Header closeButton={true}>            
              <strong className="me-auto">{notificationHead}</strong>
              <small>{notificationSmallHead}</small>
              </Toast.Header>
            <Toast.Body className="text-dark">{notificationMessage}</Toast.Body>
          </Toast>
        </ToastContainer>
     {/* <Notification head='Head' smallhead='small herad' message='Message' bg="default" />*/}
     <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{modalHead}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalBody}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {/*
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
            */}
          </Modal.Footer>
    </Modal>        
      <div className="inner-body">
          <h3 className="heading">LINK CLICK</h3>
          <p style={{textAlign:'center'}}>
            Shorten your links, convert your links to QRcodes!
            <br/>
            You can monitor who clicks your link and use the data to drive your business!
          </p>
          
            <div className="container-fluid">
                <form method="POST" onSubmit={getStarted}>

                  <input id="newLink" type="url" className="form-control" onChange={handleLink} placeholder="Your Link"  required/>

                  <button className="btn btn-primary mt-2" type="submit">Get Started</button>

                </form>
            </div>
      </div>


   </div>
   <Footer />
  </>
  );
 
}




export default Home;
