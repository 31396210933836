import '../css/style.css'
import logo from '../assets/logo.png'
import $, { cssNumber } from 'jquery';
import {Button,ButtonGroup,ButtonToolbar,Modal,ModalBody,ModalCOntext,ModalDialog,ModalFooter,ModalHeader,ModalTitle} from 'react-bootstrap';
import { Toast, ToastContainer} from 'react-bootstrap';
import * as api from '../api';
import {useState, useEffect} from 'react';
import Links from '../Modules/Links';
import Analytics from '../Modules/Analytics';
import ViewLink from '../Modules/ViewLink';

function Account(props) { 

         //THIS HANDLES THE MODAL
         const [show, setShow] = useState(false);  
         const handleClose = () => setShow(false);
         const handleShow = () => setShow(true);
         const[modalHead,setModalHead]=useState("Link Click");    
         const[modalBody,setModalBody]=useState("Link Click");
         
     
     
         //THIS HANDLES THE TOAST
         const [notification, setNotification] = useState(false);
         const handleNotification = () => setNotification(true);
         const[notificationHead,setNotificationHead]=useState("");
         const[notificationSmallHead,setNotificationSmallHead]=useState("");    
         const[notificationMessage,setNotificationMessage]=useState("");

         const [email,setEmail]=useState(sessionStorage.getItem("email"));
         const [password,setPassword]=useState(sessionStorage.getItem("password"));
         const [id,setId]=useState(sessionStorage.getItem("id"));
         const [lastSeen,setLastSeen]=useState(sessionStorage.getItem("lastSeen"));
         const [dateCreated,setDateCreated]=useState(sessionStorage.getItem("dateCreated"));
         const [timeCreated,setTimeCreated]=useState(sessionStorage.getItem("timeCreated"));
      
       
       
         useEffect(()=>{                         
           //setInterval(viewLink,1000);
     },[]); 
/*
        const viewLink=()=>{            
            var view=sessionStorage.getItem("viewLinkStatus")                        
            var viewId=sessionStorage.getItem("viewLinkId")            
            if(view=='true'){
                sessionStorage.setItem("viewLinkStatus",false);
                $("#Links").hide(function(){
                    $("#ViewLinks").show();                    
                });
                
            }

            var returnn=sessionStorage.getItem("returnLinkStatus")
            if(returnn==true){

            }

            
        }
*/

    var path = window.location.pathname //returns the current url minus the domain name
  
    var pathname=path.replace("/account/","");
/*
    if(pathname!="/account"){
        ("Yep")
    }
  */     

         
    if(sessionStorage.getItem("logged")!='true'){
        window.location.href='/login';
    }
   
    const logout= ()=>{
        sessionStorage.clear();
        window.location.href='/login';
    }        

   //loadLinks()
  return (
    <>
        <ToastContainer className="p-3" position='top-end'>
            <Toast  className="" onClose={() => setNotification(false)} show={notification} >
            <Toast.Header closeButton={true}>            
                <strong className="me-auto">{notificationHead}</strong>
                <small>{notificationSmallHead}</small>
                </Toast.Header>
            <Toast.Body className="text-dark">{notificationMessage}</Toast.Body>
            </Toast>
        </ToastContainer>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>{modalHead}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalBody}</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            {/*
            <Button variant="primary" onClick={handleClose}>
                Save Changes
            </Button>
            */}
            </Modal.Footer>
        </Modal> 
        <div className='Page'>
            <div className="Account scrollable rounded px-3 pt-3">
                <header className="pb-0 mb-0">
                    <div style={{float:"right"}}>
                        <button className="btn btn-danger" onClick={logout}><i className="bx bx-log-out"></i></button>
                    </div>
                    <h5>{email}</h5>
                   
                </header>
                <hr/>
                <div>
                    <Analytics />
                </div>
              <div id="Links">
                    <Links />
              </div>
            

            </div>
        </div>
    </>
    
  );
  
}

export default Account;
