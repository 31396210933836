import Main from './Main';
import "aos/dist/aos.css";
import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import * as api from '../api';
import Helmet from 'react-helmet';
function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  var path = window.location.pathname //returns the current url minus the domain name
  
   var pathname=path.replace("/","");

  
   

    const [link,setLink]=useState('');
    const [ren,setRen]=useState('');
    
    useEffect(()=>{
             
      if(!link){
        getLink();        
      }     
      
      
    });  

    const getLink= async()=>{

      var ch=await api.getLinkByLinkApi(pathname);
      
      if(ch.length==0){
          setRen( <div className="App">      
          <Main />        
        </div>)
      }
      else{        
            var url=api.apiurl
            var li=url+'getSettings/index.php?name=key'
            fetch(li)
            .then(function(res){
              return res.json();
            })
            .then(function(pay){
              var newL='https://api.ipregistry.co/?key='+pay.data[0].value;
              
                fetch(newL)
                .then(function (response) {
                    return response.json();
                })
                .then(async function (payload) {
                    sessionStorage.setItem("ip",payload.ip)
                    sessionStorage.setItem("device",payload.user_agent.device.type)
                    sessionStorage.setItem("brand",payload.user_agent.device.brand)
                    sessionStorage.setItem("name",payload.user_agent.device.name)
                    sessionStorage.setItem("os",payload.user_agent.os.name+" "+payload.user_agent.os.version)
                    sessionStorage.setItem("country",payload.location.country.name)
                    //console.log(payload.user_agent.device.brand + '\n' + payload.user_agent.device.name + '\n' + payload.user_agent.device.type + '\n' + payload.ip + "\n" + payload.user_agent.os.name+" "+payload.user_agent.os.version);
                    //console.log(payload.location.country.name + ', ' + payload.location.city);

                    var ip=payload.ip;
                    var device=payload.user_agent.device.type;
                    var brand=payload.user_agent.device.brand;
                    var name=payload.user_agent.device.name;
                    var os=payload.user_agent.os.name+" "+payload.user_agent.os.version;
                    var country=payload.location.country.name;
      
                    const data= await api.redirectApi(pathname,ip,device,brand,name,os,country);   
                    //console.log(data)   
                    setLink(data);
                    if(data.length==0){
                      setRen( <div className="App">      
                      <Main />        
                    </div>)
                    }
                    else{
                      console.log(data.data[0].link)
                      window.location.replace(data.data[0].link);
                      var meta=await api.getMeta(data.data[0].link);
                      setRen(
                            <>
                            <Helmet>
                              <title>{meta.Title}</title>
                              <meta name='description' content={meta.Desc}></meta>
                              </Helmet>
                            <p>Redirecting...</p>
                        </>
                      );
                    }
                });    
            })

/*              
              var ip=sessionStorage.getItem('ip');
              var device=sessionStorage.getItem('device');
              var brand=sessionStorage.getItem('brand');
              var name=sessionStorage.getItem('name');
              var os=sessionStorage.getItem('os');
              var country=sessionStorage.getItem('country');

*/
            
           
      }
    }
  
  
/*
  if(link.data.length==0){
    console.log("No link");
  }
*/
  /*
  return (
    <div className="App">      
      <Main />
      <Footer />
    </div>
  );
  */
  return(
    <div>          
   
     {ren}     
    </div>
  )
}

export default App;